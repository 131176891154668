.rc-slider {
  height: 32px;
  pointer-events: none !important;
  padding: 0 !important;
  border-radius: 8;
  overflow: hidden;
}

.rc-slider-rail {
  border-radius: 8;
  height: 32px;
  color: '#DDDDDD';
}

.rc-slider-track {
  height: 32px;
  color: #53C6D6;
  background-color: #53C6D6;
  border-radius: 0;
  pointer-events: all !important;
  cursor: pointer !important;
}

.rc-slider-handle {
  pointer-events: all !important;
  color: #53C6D6;
  margin-left: -0.5;
  margin-top: 0;
  border-radius: 0;
  height: 32px;
  padding-left: 7px;
  width: 1px;
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
}

.rc-slider-handle-1{
  cursor: w-resize !important;
}

.rc-slider-handle-2{
  cursor: e-resize !important;
}
