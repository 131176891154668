.tox-notifications-container {
  display: none;
}

.photoshop-picker.text-interactions {
  width: 90% !important;
  margin-bottom: 16px;
}

.button-action-text-interaction button {
  background-color: #29a96e !important;
}

.button-action-text-interaction button:hover {
  background-color: #196542 !important;
}

.button-action-text-interaction .MuiButton-label {
  color: white;
}

.css-tz00yp {
  background-color: #f4f5f7 !important;
}

.css-1ct9n15-MuiTabs-indicator {
  background-color: #ed257a !important;
}

.arrow-pick-color::before {
  content: '';
  display: block;
  position: absolute;
  border-width: 6px 8px;
  border-style: solid;
  border-color: #c4c4c4 transparent transparent transparent;
  top: 4px;
  right: -20px;
}

#transparent-button-react-color-custom .sketch-picker {
  width: 160px !important;
  padding: 4px 4px 0px !important;
  box-sizing: initial;
  border-radius: 4px;
}
