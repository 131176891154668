/* .rnd div>div {
  cursor: auto !important;
} */

div[style*='position: absolute; user-select: none; width: 100%; height: 10px; top: -5px; left: 0px; cursor: row-resize;'] {
  cursor: auto !important;
}
div[style*='position: absolute; user-select: none; width: 10px; height: 100%; top: 0px; right: -5px; cursor: col-resize;'] {
  cursor: auto !important;
}
div[style*='position: absolute; user-select: none; width: 100%; height: 10px; bottom: -5px; left: 0px; cursor: row-resize;'] {
  cursor: auto !important;
}
div[style*='position: absolute; user-select: none; width: 10px; height: 100%; top: 0px; left: -5px; cursor: col-resize;'] {
  cursor: auto !important;
}

.image-interaction-fadeOut {
  transition: visibility 0s linear 400ms, opacity 400ms;
}
.image-interaction-fadeIn {
  transition: visibility 0s linear 0s, opacity 400ms;
}

.image-interaction-jump-to-schedule-checked {
  position: absolute;
  bottom: 0;
  left: 48%;
  background-color: #043348;
  height: 12px;
  width: 16px;
  border-radius: 4px 4px 0 0;
}

.image-interaction-jump-to-schedule-checked-icon {
  font-size: 12px !important;
  color: white;
  position: absolute;
  top: 0;
  left: 2px;
}
