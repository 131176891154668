/* .rnd div>div {
  cursor: auto !important;
} */

div[style*='position: absolute; user-select: none; width: 100%; height: 10px; top: -5px; left: 0px; cursor: row-resize;'] {
  cursor: auto !important;
}
div[style*='position: absolute; user-select: none; width: 10px; height: 100%; top: 0px; right: -5px; cursor: col-resize;'] {
  cursor: auto !important;
}
div[style*='position: absolute; user-select: none; width: 100%; height: 10px; bottom: -5px; left: 0px; cursor: row-resize;'] {
  cursor: auto !important;
}
div[style*='position: absolute; user-select: none; width: 10px; height: 100%; top: 0px; left: -5px; cursor: col-resize;'] {
  cursor: auto !important;
}

* {
  background-image: linear-gradient(45deg, #cbcbcb 25%, transparent 0, transparent 75%, #cbcbcb 0, #cbcbcb),
    linear-gradient(45deg#cbcbcb 25%, transparent 0, transparent 75%, #cbcbcb 0, #cbcbcb);
}
