.react-flow__controls {
  top: 10px !important;
  left: initial !important;
  right: 10px !important;
  display: flex !important;
  height: 30px !important;
  flex-direction: row-reverse !important;
}

.react-flow__controls-button {
  height: 24px !important;
  width: 24px !important;
}
.react-flow__controls-button svg {
  max-height: 16px !important;
  max-width: 16px !important;
}