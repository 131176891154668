.select-sound-modal audio::-webkit-media-controls-mute-button {
  display: none !important;
}

.select-sound-modal audio::-webkit-media-controls-volume-slider {
  display: none !important;
}

.select-sound-modal audio::-webkit-media-controls-timeline {
  min-width: 50px;
}
.select-sound-modal {
  margin-top: 6px;
}