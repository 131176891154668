.only-css-to-timeline-slider .MuiSlider-valueLabelCircle {
  border-radius: 4px !important;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  width: auto !important;
  height: auto !important;
  padding: 4px;
  font-weight: bold;
}

.only-css-to-timeline-slider .MuiSlider-valueLabelLabel {
  color: #ffffff;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.css-1saz8np-MuiSlider-valueLabel {
  margin-top: 28px !important;
}

.only-css-to-timeline-slider .css-wfrqet-MuiSlider-thumb::before {
  content: '';
  display: block;
  position: absolute;
  border-width: 10px 10px;
  border-style: solid;
  border-color: #ed257a transparent transparent transparent;
  top: 4px;
}

.only-css-to-timeline-slider .css-wfrqet-MuiSlider-thumb::after {
  border-radius: 0 !important;
  right: 0 !important;
  left: -9px !important;
  top: 0 !important;
  bottom: -7px !important;

  border-width: 10px 10px;
  border-style: solid;
  border-color: transparent transparent #ed257a transparent;
}
