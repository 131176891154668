/* .rnd div>div {
  cursor: auto !important;
} */

div[style*='position: absolute; user-select: none; width: 100%; height: 10px; top: -5px; left: 0px; cursor: row-resize;'] {
  cursor: auto !important;
}
div[style*='position: absolute; user-select: none; width: 10px; height: 100%; top: 0px; right: -5px; cursor: col-resize;'] {
  cursor: auto !important;
}
div[style*='position: absolute; user-select: none; width: 100%; height: 10px; bottom: -5px; left: 0px; cursor: row-resize;'] {
  cursor: auto !important;
}
div[style*='position: absolute; user-select: none; width: 10px; height: 100%; top: 0px; left: -5px; cursor: col-resize;'] {
  cursor: auto !important;
}

.pause-interaction-fadeOut {
  transition: visibility 0s linear 400ms, opacity 400ms;
}
.pause-interaction-fadeIn {
  transition: visibility 0s linear 0s, opacity 400ms;
}

