.waocon-interaction-bounding-box {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 30px);
  width: 100%;
  z-index: 2;
  overflow: hidden;
}

.video-player {
  position: relative;
  margin: 0 auto;
}

.video-player-grid {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.video-player-grid.video-player-grid--off {
  display: none;
}

.video-react-big-play-button.video-react-big-play-button-center {
  z-index: 3;
}

.video-react .video-react-big-play-button {
  visibility: hidden;
}
.video-player .video-react-control-bar {
  z-index: 2;
}

.video-player .video-react-control-bar .video-react-icon-fullscreen {
  display: none;
}
