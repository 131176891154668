.react-flow__edge-path {
  stroke-width: 4 !important;
}

.react-flow__edge-path:hover {
  stroke: #87CDCF !important;
}

.react-flow__edge {
  cursor: pointer !important;
}

.react-flow__edge.selected .react-flow__edge-path {
  stroke: #87CDCF !important;
  cursor: auto !important;
}